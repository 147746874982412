/*------------------------------------------------------------------
[Master Stylesheet]

Project:	MINIFY - Minimal Portfolio Template
Version:	1.1
Primary use:	Portfolio 
-------------------------------------------------------------------*/

/*     

	CSS INDEX
	----------------
	
    1. Base CSS 
	2. Typography
	3. Progress Bars
	4. Headers
	5. Banners
	6. Portfolio
	7. Portfolio with Info Box
	8. Blog Small
	9. Single Project
	10. About Pages
	12. Pricing Tables
	13. Blog Pages
	14. Contact Pages
	15. Footers
	16. Scroll to Top
	17. Preloader
	18. Responsive
*/

@import url('https://fonts.googleapis.com/css?family=Raleway:400,700,800,900');

.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
	max-width: 1170px;
}


/* Base CSS */
body {
  color: #666666;
  background: #fefefe;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
}

html, body {
  height: 100%;
  margin: 0;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  color: #111111;
  font-family: "Raleway", sans-serif;
  font-weight: 800;
  letter-spacing: 0.1em;
  margin-top: 0;
}

.dark h1, .dark h2, .dark h3, .dark h4, .dark h5, .dark h6 {
  color: white;
}

h1 {
  font-size: 36px;
  margin-bottom: 24px;
}

h2 {
  font-size: 30px;
  margin-bottom: 22px;
}

h3 {
  font-size: 26px;
  margin-bottom: 20px;
}

h4 {
  font-size: 22px;
  margin-bottom: 18px;
}

h5 {
  font-size: 18px;
  margin-bottom: 14px;
}

h6 {
  font-size: 16px;
  margin-bottom: 12px;
}

a {
  color: #333;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  letter-spacing: 0.05em;
  font-weight: 700;
  outline: 0 !important;
  text-decoration: none;
}
a:hover, a:active, a:focus {
  color: #888;
  text-decoration: none;
}

p {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
}

.dark p {
  color: #ebebeb;
}

ul {
  list-style: none;
  padding: 0;
}

ul, ol {
  padding-left: 16px;
  margin-top: 0;
  margin-bottom: 20px;
}

.list-featured {
  list-style: none;
  padding: 0;
}

.list-featured li {
  position: relative;
  padding-left: 14px;
  margin-bottom: 7px;
}

.list-featured li:before {
  position: absolute;
  display: block;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 0;
  left: 0;
  top: 7px;
  background-color: #c39d6d;
}

ol li {
  margin-bottom: 7px;
}

li {
  color: #333;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
}

blockquote {
  background: #f6f6f6 none repeat scroll 0 0;
  border-left: 3px solid #666;
  color: #333;
  padding: 15px 20px;
}

.blockquote-reverse {
  border-right: 3px solid #c39d6d;
}

blockquote p {
  font-size: 16px;
  font-weight: 400;
}

cite {
  color: #c39d6d;
  font-size: 12px;
  opacity: 0.75;
}

strong {
  font-weight: 700;
}

.text-uppercase {
  text-transform: uppercase;
}

.lead {
  font-size: 18px;
}

.text-sm {
  font-size: 14px;
}

.text-xs {
  font-size: 12px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-default {
  color: #333333;
}

.text-primary {
  color: #c39d6d;
}

.text-success {
  color: #93bc49;
}

.text-info {
  color: #54bedc;
}

.text-warning {
  color: #e2a740;
}

.text-danger {
  color: #de3f3f;
}

.bg-default {
  color: white;
  background: #333333;
  padding: 3px 4px;
}

.bg-primary {
  color: white;
  background: #c39d6d;
  padding: 3px 4px;
}

.bg-success {
  color: white;
  background: #93bc49;
  padding: 3px 4px;
}

.bg-info {
  color: white;
  background: #54bedc;
  padding: 3px 4px;
}

.bg-warning {
  color: white;
  background: #e2a740;
  padding: 3px 4px;
}

.bg-danger {
  color: white;
  background: #de3f3f;
  padding: 3px 4px;
}

/* / End Typography */
.row {
  margin-right: 0;
  margin-left: 0;
}

img {
  width: 100%;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  -webkit-backface-visibility: hidden;
}

img.btn-pill {
  border: 1px solid transparent;
}

.page-header {
  font-size: 30px;
  font-weight: 700;
  border: none;
  margin-top: 0;
  margin-bottom: 30px;
}

.page-header h2 {
  margin: 0;
}

.page-header.wsub {
  margin-bottom: 0;
}

.page-header.wsub h2 {
  margin-bottom: 0;
}

.sub-title {
  color: #111111;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.dark {
  background-color: #333333;
}

.space-top {
  margin-top: 20px !important;
}

.space-top-30 {
  margin-top: 30px !important;
}

.space-top-2x {
  margin-top: 40px !important;
}

.space-bottom {
  margin-bottom: 20px !important;
}

.space-bottom-30 {
  margin-bottom: 30px !important;
}

.space-bottom-2x {
  margin-bottom: 40px !important;
}

.space-left {
  padding-left: 15px !important;
}

.space-left-2x {
  padding-left: 30px !important;
}

.space-right {
  padding-right: 15px !important;
}

.space-right-2x {
  padding-right: 30px !important;
}

.space-25 {
  margin: 12.5px 0;
  line-height: 0;
}

.space-50 {
  margin: 25px 0;
  line-height: 0;
}

.space-100 {
  margin: 50px 0;
  line-height: 0;
}

.space-200 {
  margin: 100px 0;
  line-height: 0;
}

.margin-left {
  margin-left: 15px;
}

.margin-left-2x {
  margin-left: 30px;
}

.margin-right {
  margin-right: 15px;
}

.margin-right-2x {
  margin-right: 30px;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-border {
  border: none !important;
}

.no-border-radius {
  border-radius: 0 !important;
}

.rounded {
  border-radius: 5px !important;
}

a:focus {
  outline: none !important;
}

.btn:focus {
  outline: none !important;
}

.btn-social:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.fa {
  display: initial !important;
}

/* Animation Durations */
.animated {
  -webkit-animation-duration: 1s !important;
  animation-duration: 1s !important;
}

.animated.fast {
  -webkit-animation-duration: 0.4s !important;
  animation-duration: 0.4s !important;
}

.animated.first {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 0.5s;
}

.animated.second {
  -webkit-animation-duration: 1.6s !important;
  animation-duration: 1.6s !important;
  animation-delay: 1s;
}

.animated.third {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 1.3s;
}

.animated.fourth {
  -webkit-animation-duration: 1.6s !important;
  animation-duration: 1.6s !important;
  animation-delay: 1.8s;
}

.animated.fifth {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 2s;
}

.animated.sixth {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 2.5s;
}

.animated.seventh {
  -webkit-animation-duration: 1.6s !important;
  animation-duration: 1.6s !important;
  animation-delay: 3.2s;
}

.animated.eighth {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 3.5s;
}

.animated.nineth {
  -webkit-animation-duration: 1.6s !important;
  animation-duration: 1.6s !important;
  animation-delay: 3.8s;
}

/* End Animation Durations */
::-moz-selection {
  color: white;
  background: #444;
}

::selection {
  color: white;
  background: #444;
}

/* Buttons */
.btn {
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.05em;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 0;
  padding: 10px 18px;
  margin: 5px;
}

.btn span {
  margin-left: 18px;
  margin-right: 18px;
}

.btn:hover {
  background: transparent;
}

.btn:focus {
  background: transparent;
}

.btn-lg {
  font-size: 15px;
  padding: 12px 20px;
}

.btn-sm {
  font-size: 12px;
  padding: 8px 14px;
}

.btn-xs {
  font-size: 10px;
  padding: 5px 12px;
}

.btn-rounded {
  border-radius: 5px !important;
}

.btn-pill {
  border-radius: 50px !important;
}

.btn-circle {
  border-radius: 50% !important;
  height: 40px;
  width: 40px;
  padding: 3px !Important;
}

.btn-square {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  padding: 3px !Important;
}

.btn-link {
  color: #c39d6d;
}

.btn-link:hover {
  color: #ad8b60;
  text-decoration: none;
}

.btn-link:focus {
  color: #ad8b60;
  text-decoration: none;
}

a.btn {
  white-space: nowrap;
}

/* Default Button */
.btn-default, .btn-default:active, .btn-default:focus {
  color: #333333;
  border: 2px solid #333333;
  background: transparent;
}

.btn-default:hover {
  color: white;
  background: #333333;
  border: 2px solid #333333;
}

.btn-default-filled, .btn-default-filled:active, .btn-default-filled:focus {
  color: white;
  background: #333333;
  border: 2px solid #333333;
}

.btn-default-filled:hover {
  color: white;
  background: #111111;
  border: 2px solid #111111;
}

/* / End Default Button */
/* Primary Button */
.btn-primary, .btn-primary:active, .btn-primary:focus {
  color: #c39d6d;
  border: 2px solid #c39d6d;
  background: transparent;
}

.btn-primary:hover {
  color: white;
  background: #c39d6d;
  border: 2px solid #c39d6d;
}

.btn-primary-filled, .btn-primary-filled:active, .btn-primary-filled:focus {
  background: #333 none repeat scroll 0 0;
  border: 2px solid #333;
  color: white;
}

.btn-primary-filled:hover {
  background: #666 none repeat scroll 0 0;
  border: 2px solid #666;
  color: white;
}

/* / End Primary Button */
/* Success Button */
.btn-success, .btn-success:active, .btn-success:focus {
  color: #93bc49;
  border: 2px solid #93bc49;
  background: transparent;
}

.btn-success:hover {
  color: white;
  background: #93bc49;
  border: 2px solid #93bc49;
}

.btn-success-filled, .btn-success-filled:active, .btn-success-filled:focus {
  color: white;
  background: #93bc49;
  border: 2px solid #93bc49;
}

.btn-success-filled:hover {
  color: white;
  background: #7ea03f;
  border: 2px solid #7ea03f;
}

/* / End Success Button */
/* Info Button */
.btn-info, .btn-info:active, .btn-info:focus {
  color: #54bedc;
  border: 2px solid #54bedc;
  background: transparent;
}

.btn-info:hover {
  color: white;
  background: #54bedc;
  border: 2px solid #54bedc;
}

.btn-info-filled, .btn-info-filled:active, .btn-info-filled:focus {
  color: white;
  background: #54bedc;
  border: 2px solid #54bedc;
}

.btn-info-filled:hover {
  color: white;
  background: #3a9fbc;
  border: 2px solid #3a9fbc;
}

/* / End Info Button */
/* Warning Button */
.btn-warning, .btn-warning:active, .btn-warning:focus {
  color: #e2a740;
  border: 2px solid #e2a740;
  background: transparent;
}

.btn-warning:hover {
  color: white;
  background: #e2a740;
  border: 2px solid #e2a740;
}

.btn-warning-filled, .btn-warning-filled:active, .btn-warning-filled:focus {
  color: white;
  background: #e2a740;
  border: 2px solid #e2a740;
}

.btn-warning-filled:hover {
  color: white;
  background: #cb9538;
  border: 2px solid #cb9538;
}

/* / End Warning Button */
/* Danger Button */
.btn-danger, .btn-danger:active, .btn-danger:focus {
  color: #de3f3f;
  border: 2px solid #de3f3f;
  background: transparent;
}

.btn-danger:hover {
  color: white;
  background: #de3f3f;
  border: 2px solid #de3f3f;
}

.btn-danger-filled, .btn-danger-filled:active, .btn-danger-filled:focus {
  color: white;
  background: #de3f3f;
  border: 2px solid #de3f3f;
}

.btn-danger-filled:hover {
  color: white;
  background: #bf3535;
  border: 2px solid #bf3535;
}

/* / End Danger Button */
/* Button Groups */
.btn-group-xl > .btn {
  font-size: 20px;
  padding: 9px 10px;
  border-radius: 5px;
}

.btn-group-lg > .btn {
  font-size: 15px;
  padding: 10px 12px;
  border-radius: 5px;
}

.btn-group > .btn {
  border-radius: 5px;
}

.btn-group-sm > .btn {
  font-size: 12px;
  padding: 5px 4px;
  border-radius: 5px;
}

.btn-group-xs > .btn {
  font-size: 10px;
  padding: 3px;
  border-radius: 5px;
}

/* / End Button Groups */
/* Dropdown Buttons */
.dropdown-buttons {
  display: inline-block;
  position: relative;
}

.dropdown-buttons .btn-group > .btn {
  padding-left: 17px;
}

.dropdown-buttons .dropdown-icon {
  margin: 0 2px 0 10px;
}

.dropdown-buttons .btn {
  border-radius: 0;
}

.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open > .dropdown-toggle.btn-default.focus, .open > .dropdown-toggle.btn-default:focus, .open > .dropdown-toggle.btn-default:hover {
  color: white;
  background-color: #333333;
  border-color: #333333;
}

.btn-default.active, .btn-default:active, .open > .dropdown-toggle.btn-default {
  color: white;
  background-color: #333333;
  border-color: #333333;
}

.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus, .open > .dropdown-toggle.btn-primary:hover {
  color: white;
  background-color: #c39d6d;
  border-color: #c39d6d;
}

.btn-primary.active, .btn-primary:active, .open > .dropdown-toggle.btn-primary {
  color: white;
  background-color: #c39d6d;
  border-color: #c39d6d;
}

.btn-success.active.focus, .btn-success.active:focus, .btn-success.active:hover, .btn-success:active.focus, .btn-success:active:focus, .btn-success:active:hover, .open > .dropdown-toggle.btn-success.focus, .open > .dropdown-toggle.btn-success:focus, .open > .dropdown-toggle.btn-success:hover {
  color: white;
  background-color: #93bc49;
  border-color: #93bc49;
}

.btn-success.active, .btn-success:active, .open > .dropdown-toggle.btn-success {
  color: white;
  background-color: #93bc49;
  border-color: #93bc49;
}

.btn-info.active.focus, .btn-info.active:focus, .btn-info.active:hover, .btn-info:active.focus, .btn-info:active:focus, .btn-info:active:hover, .open > .dropdown-toggle.btn-info.focus, .open > .dropdown-toggle.btn-info:focus, .open > .dropdown-toggle.btn-info:hover {
  color: white;
  background-color: #54bedc;
  border-color: #54bedc;
}

.btn-info.active, .btn-info:active, .open > .dropdown-toggle.btn-info {
  color: white;
  background-color: #54bedc;
  border-color: #54bedc;
}

.btn-warning.active.focus, .btn-warning.active:focus, .btn-warning.active:hover, .btn-warning:active.focus, .btn-warning:active:focus, .btn-warning:active:hover, .open > .dropdown-toggle.btn-warning.focus, .open > .dropdown-toggle.btn-warning:focus, .open > .dropdown-toggle.btn-warning:hover {
  color: white;
  background-color: #e2a740;
  border-color: #e2a740;
}

.btn-warning.active, .btn-warning:active, .open > .dropdown-toggle.btn-warning {
  color: white;
  background-color: #e2a740;
  border-color: #e2a740;
}

.btn-danger.active.focus, .btn-danger.active:focus, .btn-danger.active:hover, .btn-danger:active.focus, .btn-danger:active:focus, .btn-danger:active:hover, .open > .dropdown-toggle.btn-danger.focus, .open > .dropdown-toggle.btn-danger:focus, .open > .dropdown-toggle.btn-danger:hover {
  color: white;
  background-color: #de3f3f;
  border-color: #de3f3f;
}

.btn-danger.active, .btn-danger:active, .open > .dropdown-toggle.btn-danger {
  color: white;
  background-color: #de3f3f;
  border-color: #de3f3f;
}

/* / End Dropdown Buttons */
/* / End Buttons */
/* Tabs & Accordions */
/* Tabs */
.nav-tabs {
  border-bottom: 3px solid #ebebeb;
}

.nav-tabs > li {
  float: none;
  margin-bottom: -3px;
  display: inline-block;
}

.nav-tabs > li > a {
  position: relative;
  z-index: 0;
  display: block;
  color: #666666;
  font-size: 12px;
  text-decoration: none;
  font-weight: 700;
  margin-right: 0;
  padding: 20px;
  line-height: 1.5;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 0;
}

.nav-tabs > li > a:focus {
  background: transparent;
  border: 0 !important;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
  color: #c39d6d;
  background: none;
  border: none;
  border-bottom: 3px solid #c39d6d !important;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}

.nav > li > a:hover {
  background: none;
  border: none;
}

.nav-tabs > li {
  float: none;
  margin-bottom: -3px;
  display: inline-block;
}

.nav-pills.text-right > li {
  float: none;
  display: inline-block;
}

.nav.nav-pills a:hover {
  color: white;
  background: #ad8b60;
}

.tab-content {
  padding-top: 30px;
}

/* / End Tabs */
/* Accordions */
.panel-group {
  margin-bottom: 10px;
}

.panel-group .panel {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}

.panel-group .panel-heading {
  padding: 10px 0;
  border: none;
  border-radius: 0;
}

.panel-group .panel-title {
  font-size: 14px;
  font-weight: 400;
}

.panel-group .panel-heading + .panel-collapse > .list-group, .panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top: none;
}

.panel-group .panel-heading > a:after {
  display: inline-block;
  vertical-align: middle;
  font-family: "FontAwesome";
  font-size: 10px;
  content: "\f068";
  margin-top: -2px;
  margin-left: 10px;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.panel-group .panel-heading .panel-title.collapsed:after {
  content: "\f067";
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.panel-group .panel-body {
  padding: 15px 0;
}

.panel-group.tabbed .panel-heading {
  background: #ebebeb;
  padding: 10px 15px;
}

.panel-group.tabbed a {
  color: #666666;
}

.panel-group.tabbed .panel-heading > a:after {
  float: right;
}

.panel-group.tabbed .panel-heading .panel-title.collapsed:after {
  float: right;
}

/* / End Accordions */
/* / End Tabs & Accordions */
/* Labels Badges & Alerts */
/* Labels */
.label {
  font-weight: 400;
  padding: .2em .6em;
  line-height: 2.5em;
  border-radius: 0;
}

.label-pill {
  border-radius: 5em;
}

.label-default {
  color: #333333;
  background-color: transparent;
  border: 2px solid #333333;
}

.label-default-filled {
  color: white;
  background-color: #333333;
  border: 2px solid #333333;
}

.label-primary {
  color: #c39d6d;
  background-color: transparent;
  border: 2px solid #c39d6d;
}

.label-primary-filled {
  color: white;
  background-color: #c39d6d;
  border: 2px solid #c39d6d;
}

.label-success {
  color: #93bc49;
  background-color: transparent;
  border: 2px solid #93bc49;
}

.label-success-filled {
  color: white;
  background-color: #93bc49;
  border: 2px solid #93bc49;
}

.label-info {
  color: #54bedc;
  background-color: transparent;
  border: 2px solid #54bedc;
}

.label-info-filled {
  color: white;
  background-color: #54bedc;
  border: 2px solid #54bedc;
}

.label-warning {
  color: #e2a740;
  background-color: transparent;
  border: 2px solid #e2a740;
}

.label-warning-filled {
  color: white;
  background-color: #e2a740;
  border: 2px solid #e2a740;
}

.label-danger {
  color: #de3f3f;
  background-color: transparent;
  border: 2px solid #de3f3f;
}

.label-danger-filled {
  color: white;
  background-color: #de3f3f;
  border: 2px solid #de3f3f;
}

/* / End Labels */
/* Badges */
.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  color: white;
  background-color: #c39d6d;
}

.nav-pills > li > a:hover {
  color: white;
  background-color: #cccccc;
}

.nav-pills > li > a {
  font-size: 12px;
  border-radius: 0;
  padding: 8px 14px;
}

.badge {
  color: white;
  background-color: #c39d6d;
  font-size: 10px;
  font-weight: 400;
  border-radius: 0;
  padding: 6px 8px;
}

.active .badge {
  color: #c39d6d !important;
}

/* / End Badges */
/* Alerts */
.alert {
  border: 2px solid transparent;
  border-radius: 0;
}

.alert-success {
  color: white;
  background-color: #93bc49;
  border-color: #7ea03f;
}

.alert-success .close {
  color: #7ea03f;
}

.alert-info {
  color: white;
  background-color: #54bedc;
  border-color: #3a9fbc;
}

.alert-info .close {
  color: #3a9fbc;
}

.alert-warning {
  color: white;
  background-color: #e2a740;
  border-color: #cb9538;
}

.alert-warning .close {
  color: #cb9538;
}

.alert-danger {
  color: white;
  background-color: #de3f3f;
  border-color: #bf3535;
}

.alert-danger .close {
  color: #bf3535;
}

.alert-dismissible .close {
  font-size: 16px;
  top: -14px;
  right: -31px;
  text-shadow: none;
  opacity: 1;
}

.alert-dismissible .close:hover {
  opacity: 0.8;
}

.alert-sm {
  font-size: 14px;
  padding: 5px;
}

.alert i {
  margin: 0 10px 0 5px;
}

.alert-sm.alert-dismissible .close {
  top: -5px;
  right: -2px;
}

/* / End Alerts */
/* / End Labels Badges & Alerts */
/* Listgroups & Panels */
/* Listgroups */
.list-group-item {
  color: #666666;
  background-color: white;
  font-size: 14px;
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
}

.list-group-item p {
  font-size: 14px;
}

.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
  color: white;
  background-color: #c39d6d;
  border-color: #c39d6d;
}

a.list-group-item:hover {
  color: #c39d6d;
}

a.list-group-item, button.list-group-item {
  color: #666666;
}

.list-group-item.active .list-group-item-text, .list-group-item.active:focus .list-group-item-text, .list-group-item.active:hover .list-group-item-text {
  color: white;
}

a.list-group-item .list-group-item-heading, button.list-group-item .list-group-item-heading {
  color: #111111;
}

/* / End Listgroups */
/* Panels */
.panel-title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.05em;
}

.panel-default {
  border-color: #111111;
}

.panel-default > .panel-heading {
  color: white;
  background-color: #333333;
  border-color: #111111;
}

.panel-success {
  border-color: #7ea03f;
}

.panel-success > .panel-heading {
  color: white;
  background-color: #93bc49;
  border-color: #7ea03f;
}

.panel-primary {
  border-color: #ad8b60;
}

.panel-primary > .panel-heading {
  color: white;
  background-color: #c39d6d;
  border-color: #ad8b60;
}

.panel-info {
  border-color: #3a9fbc;
}

.panel-info > .panel-heading {
  color: white;
  background-color: #54bedc;
  border-color: #3a9fbc;
}

.panel-warning {
  border-color: #cb9538;
}

.panel-warning > .panel-heading {
  color: white;
  background-color: #e2a740;
  border-color: #cb9538;
}

.panel-danger {
  border-color: #bf3535;
}

.panel-danger > .panel-heading {
  color: white;
  background-color: #de3f3f;
  border-color: #bf3535;
}

/* / End Panels */
/* Well */
.well {
  min-height: 20px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #ebebeb;
  border: 1px solid #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

.rounded-well {
  border-radius: 5px;
}

/* / End Well */
/* Jumbotron */
.jumbotron {
  background: #ebebeb;
  border: 1px solid #cccccc;
  border-radius: 0 !important;
}

.rounded-jumbotron {
  border-radius: 5px !important;
}

.jumbotron h1 {
  color: #c39d6d;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 25px;
}

.jumbotron p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 25px;
}

/* / End Jumbotron */
/* / End Listgroups & Panels */
/* Progress Bars */
.progress {
  height: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #ebebeb;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.progress-bar {
  font-size: 12px;
  line-height: 20px;
  color: white;
  background-color: #c39d6d;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
}

.progress-bar-default {
  background-color: #333333;
}

.progress-bar-primary {
  background-color: #c39d6d;
}

.progress-bar-success {
  background-color: #93bc49;
}

.progress-bar-info {
  background-color: #54bedc;
}

.progress-bar-warning {
  background-color: #e2a740;
}

.progress-bar-danger {
  background-color: #de3f3f;
}

.progress-label .sr-only {
  display: none;
}

.progress-bar span {
  border-radius: 15px;
  display: inline-block;
  height: 24px;
  letter-spacing: 0;
  line-height: 23px;
  min-width: 24px;
  padding: 0 3px;
  position: absolute;
  right: -5px;
  text-align: center;
  bottom: 0;
}

/* / End Progress Bars */
/* Tables */
table {
  width: 100%;
  max-width: 100%;
}

table > caption + thead > tr:first-child > th, table > caption + thead > tr:first-child > td, table > colgroup + thead > tr:first-child > th, table > colgroup + thead > tr:first-child > td, table > thead:first-child > tr:first-child > th, table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 1px solid #cccccc;
}

table > thead > tr > th, table > thead > tr > td {
  border-bottom-width: 2px !important;
}

table > thead > tr > th, table > thead > tr > td, table > tbody > tr > th, table > tbody > tr > td, table > tfoot > tr > th, table > tr > td {
  padding: 15px;
  line-height: 1.5;
  vertical-align: top;
  border-bottom: 1px solid #ebebeb;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f8f8f8 !important;
}

/* / End Tables *

/* header-area-start */

.tp-logo a{
  color: #333;
  font-size: 20px;
  font-weight: 700;
}

.tp-main-menu ul{
  padding: 0;
  margin: 0;
}
.tp-main-menu ul li{
  display: inline-block;
  margin-left: 30px;
  position: relative;
}
.tp-main-menu ul li .submenu{
  border-top: 5px solid #111111 ;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: left;
  position: absolute;
  right: 0;
  top: 110%;
  width: 190px;
  z-index: 999;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
}
.tp-main-menu ul li:hover .submenu{
  top: 100%;
  opacity: 1;
  visibility: visible;
}
.tp-main-menu ul li .submenu li{
  margin: 0;
  margin-bottom: 12px;
}
.tp-main-menu ul li .submenu li:last-child{
  margin-bottom: 0;
}
.tp-main-menu ul li .submenu li a{
  padding: 0;
  color: #111111;
}
.tp-main-menu ul li .submenu li:hover a{
  padding-left: 5px;
}
.tp-main-menu ul li.hasdropdown::after{
    content: "\f107 ";
    color: #333;
    font-family: FontAwesome;
    font-size: 14px;
    padding-left: 5px;
    font-weight: 700;
}
.tp-main-menu ul li a{
  padding: 35px 0;
  display: inline-block;
  color: #111111;
  font-size: 13px;
  font-weight: 700;
  transition: .3s;
}
.tp-main-menu ul li:hover > a{
  color: #666;
}

.tp-menu-bar button{
  background-color: transparent;
  border: none;
  color: #111111;
  font-size: 26px;
}


/* header-area-end */




/* / End Stacked Menu */
/* End Navigations */
/* Banners */
#header-banner {
  background-color: #ebebeb;
}

#header-banner.demo-1 {
  background: url("../images/banner-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #cccccc;
}

#header-banner.demo-2 {
  background: url("../images/banner-bg2.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #cccccc;
}

#header-banner.demo-4 {
  background: url("../images/banner-bg3.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #cccccc;
}

.banner-content {
  padding: 170px 0;
}

.banner-content.single-page {
  padding: 0px 0;
}

.banner-border {
  width: 420px;
  border: 2px solid rgba(255, 255, 255, 0.75);
  margin: 0 auto;
  padding: 10px;
}

.banner-border .banner-info {
  background: rgba(255, 255, 255, 0.75);
  width: 396px;
  margin: 0;
  padding: 50px 0;
}

.banner-info {
  background: rgba(255, 255, 255, 0.75);
  width: 400px;
  margin: 0 auto;
  padding: 50px 0;
}

.banner-info.primary {
  background: rgba(195, 157, 109, 0.75);
}

.banner-info.primary h1, .banner-info.primary p {
  color: white;
}

.banner-info h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.banner-info p {
  margin-bottom: 0;
}

/* End Banners */
/* End Headers */
/* Content */
/* Portfolio */
#portfolio {
  padding-bottom: 50px;
}

#portfolio .project {
  padding-top: 15px;
  padding-bottom: 15px;
}

#portfolio.no-gutter .project {
  margin: 0;
  padding: 0;
}

#portfolio .container-fluid {
  margin: 0;
  padding: 0;
}

#portfolio.info-box .container-fluid {
  margin: 0 0px;
}

#portfolio.w-shadow .project .img-bg-color {
  -webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  -webkit-transition: ease-in-out 0.3s;
  -moz-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
}

#portfolio.w-shadow .project:hover .img-bg-color {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

#portfolio ul.portfolio {
  margin-bottom: 0 !important;
}

.portfolio-filter {
  margin-top: 40px;
  margin-bottom: 20px;
}

.portfolio-filter.list-inline li {
  padding: 0;
  display: inline-block;
}

.portfolio-filter a {
  color: #333;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 40px;
  border: 2px solid transparent;
  margin: 0 5px;
  padding: 10px 8px;
}

.portfolio-filter a.active,.portfolio-filter a:hover {
  background-color: transparent;
  border: 2px solid #333;
  color: #333;
}


/* Hover Effect */
.project-hover-tools {
  position: absolute;
  z-index: 2;
  bottom: 46%;
  right: 36%;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s, ease-in-out 0.3s;
  -moz-transition: opacity 0.3s, ease-in-out 0.3s;
  -o-transition: opacity 0.3s, ease-in-out 0.3s;
  transition: opacity 0.3s, ease-in-out 0.3s;
}

.project:hover .project-hover-tools {
  bottom: 43%;
  right: 36%;
  text-align: center;
  visibility: visible;
  opacity: 1;
}

.m-project .project-hover-tools {
  bottom: 49%;
}

.project.m-project:hover .project-hover-tools {
  bottom: 46%;
}

.project .img-bg-color.primary {
  background: #000 none repeat scroll 0 0;
  position: relative;
}

.project .img-bg-color.default {
  background: #333333;
}

.project img {
  -webkit-transition: ease-in-out 0.3s;
  -moz-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
}

.project:hover img {
  opacity: 0.25;
}

.view-btn {
  display: inline-block;
  color: #333333;
  background-color: white;
  width: 42px;
  height: 42px;
  text-align: center;
  line-height: 45px;
  text-decoration: none;
  vertical-align: middle;
  border-radius: 50%;
  margin: 3px;
}

.view-btn:hover {
  color: white;
  background-color: #333333;
}

.open-gallery {
  background-color: #fff;
  border-radius: 50%;
  color: #333333;
  display: inline-block;
  height: 42px;
  line-height: 45px;
  margin: 3px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  width: 42px;
}

.open-gallery i {
  margin-left: 3px;
}

.open-gallery:hover {
  color: white;
  background-color: #111111;
}

.project .img-bg-color.default .open-gallery {
  color: white;
  background-color: #c39d6d;
}

.project .img-bg-color.default .open-gallery:hover {
  color: white;
  background-color: #ad8b60;
}

.project-details {
  position: absolute;
  z-index: 2;
  bottom: 5%;
  left: 10%;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s, ease-in-out 0.3s;
  -moz-transition: opacity 0.3s, ease-in-out 0.3s;
  -o-transition: opacity 0.3s, ease-in-out 0.3s;
  transition: opacity 0.3s, ease-in-out 0.3s;
}

.project:hover .project-details {
  bottom: 8%;
  left: 10%;
  text-align: left;
  visibility: visible;
  opacity: 1;
}

.m-project .project-details {
  bottom: 2%;
}

.project.m-project:hover .project-details {
  bottom: 5%;
}

.project-title {
  color: white;
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-bottom: 3px;
}

.project .skill {
  color: #f8f8f8;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.1em;
}

.mfp-arrow {
  color: white;
  opacity: 0.7 !important;
}

.mfp-arrow:hover {
  opacity: 1 !important;
}

.mfp-arrow:after {
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
  top: 5px !important;
}

.mfp-arrow-right:before {
  border-left: none !important;
}

.mfp-arrow-left:before {
  border-right: none !important;
}

.mfp-arrow-right:after {
  content: "\e876" !important;
  font-family: 'Linearicons-Free' !important;
  font-size: 30px;
  border-right: none !important;
  margin-left: 25px !important;
}

.mfp-arrow-left:after {
  content: "\e875" !important;
  font-family: 'Linearicons-Free' !important;
  font-size: 30px;
  border-right: none !important;
  margin-left: 15px !important;
}

/* End Hover Effect */
/* Portfolio with Info Box */


#portfolio.info-box .portfolio-filter a.active:hover {
  background-color: #666;
  border: 2px solid #666;
  color: white;
}

#portfolio.info-box .portfolio-filter a:hover ,#portfolio.info-box .portfolio-filter a.active{
  background-color: #666;
  border: 2px solid #666;
  color: white;
}
#portfolio.info-box .project-details {
  background: white;
  position: relative;
  left: 0;
  bottom: 0;
  text-align: center;
  border: 1px solid #ebebeb;
  visibility: visible;
  opacity: 1;
  padding: 30px;
}

#portfolio.info-box .project-details h5 {
  color: #111111;
}

#portfolio.info-box .project-details p {
  color: #666666;
  margin-bottom: 0;
}

#portfolio.info-box .project-hover-tools {
  bottom: 60%;
  right: 37%;
}

#portfolio.info-box .project:hover .project-hover-tools {
  bottom: 57%;
  right: 37%;
  text-align: center;
  visibility: visible;
  opacity: 1;
}

#portfolio.info-box .m-project .project-hover-tools {
  bottom: 58%;
}

#portfolio.info-box .project.m-project:hover .project-hover-tools {
  bottom: 55%;
}

/* End Portfolio with Info Box */
/* End Portfolio */
/* Blog Small */
#blog-small {
  background: #f8f8f8;
  padding: 80px 0;
}

.post-block {
  margin-top: 30px;
}

.post-block.slider .carousel-control {
  font-size: 14px;
}

.post-block.slider .carousel-control .lnr-chevron-left {
  position: absolute;
  color: white;
  background: #333333;
  top: 47%;
  left: 0;
  z-index: 5;
  display: inline-block;
  float: left;
  padding: 10px;
}

.post-block.slider .carousel-control .lnr-chevron-right {
  position: absolute;
  color: white;
  background: #333333;
  top: 47%;
  right: 0;
  z-index: 5;
  display: inline-block;
  float: right;
  padding: 10px;
}

.post-block.slider img:hover {
  opacity: 1;
}

.post-block img {
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.post-block img:hover {
  opacity: 0.8;
}

.small-post-text {
  background: white;
  border: 1px solid #ebebeb;
  padding: 25px;
}

.post-block h5 a {
  color: #111111;
  font-size: 18px;
  letter-spacing: 0.1em;
  font-weight: 800;
}

.post-block h5 a:hover {
  color: #888;
}

.small-post-text h5 {
  margin-bottom: 2px;
}

.small-post-meta {
  color: #666;
  font-size: 12px;
  font-weight: 700;
}

.small-post-text a {
  font-size: 12px;
}

.small-post-footer {
  border-top: 1px solid #ebebeb;
  padding-top: 20px;
  margin-bottom: 0;
}

.post-icons i {
  color: #333333;
  font-size: 16px;
  padding: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.post-icons i:hover {
  color: #888;
}

/* End Blog Small */
/* Single Pages */
#page-content {
  padding: 50px 0;
}

/* Single Project */
.project-info .info {
  margin: 15px 0;
}

.project-info span {
  margin-left: 15px;
}

.info-buttons {
  margin-top: 30px;
  padding: 20px 0;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.pagination {
  margin-top: 30px;
  padding: 25px 0;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  border-radius: 0;
  display: block;
}

.project-sidebar.w-bg {
  background-color: #f8f8f8;
  padding: 30px;
}

#project-slider {
  margin-bottom: 30px;
}

.carousel-control.left, .carousel-control.right {
  background-image: none !important;
}

.carousel-control {
  font-size: 20px;
  text-shadow: none;
}

.carousel-control .lnr-chevron-left {
  position: absolute;
  color: white;
  background: #333333;
  top: 48%;
  left: 0;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
  float: left;
  padding: 15px;
}

.carousel-control .lnr-chevron-right {
  position: absolute;
  color: white;
  background: #333333;
  top: 48%;
  right: 0;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
  float: right;
  padding: 15px;
}

/* End Single Project */
/* About Pages */
.section-w-image {
  position: relative;
}

.about-description {
  background-color: #f8f8f8;
  padding: 30px;
}

.about-img {
  position: absolute;
  background: url(../images/about-img.jpg) scroll center no-repeat;
  background-size: cover;
  height: 100%;
}

.about-me-img {
  position: absolute;
  background: url(../images/about-me-img.jpg) scroll center no-repeat;
  background-size: cover;
  height: 100%;
}

#facts {
  padding: 30px 0;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.fact-icon {
  color: #c39d6d;
  font-size: 26px;
  display: block;
  margin-top: 0;
  margin-bottom: 15px;
}

.timer {
  color: #333333;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 15px;
}

.fact-title {
  color: #333;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.team img {
  margin-bottom: 0;
}

.team .team-info-box {
  padding: 15px;
  border: 1px solid #ebebeb;
}

.team .social {
  margin-bottom: 0;
}

.social i {
  margin: 0 5px;
}

/* Pricing Tables */
#pricing {
  padding-top: 60px;
}



.pricing-table {
  text-align: center;
  margin-bottom: 30px;
}

.pricing-table ul, .pricing-table ol {
  font-weight: 700;
  margin-bottom: 0;
  padding: 0;
  text-transform: uppercase;
}

.pricing-table ul span {
  font-weight: 800;
  letter-spacing: 0.1em;
}

.pricing-title {
  background-color: #333333;
  color: white;
  font-size: 18px;
  font-weight: 700;
  padding: 20px 30px;
  text-transform: uppercase;
}

.featured .pricing-title {
  background-color: #c39d6d;
}

.pricing-table-price {
  font-weight: 700;
  padding: 20px 15px;
}
.pricing-table-title {
  margin-bottom: 35px;
}

.pricing-table-price p {
  color: #111111;
}

.featured .pricing-currency {
  color: #c39d6d;
}

.featured .pricing-price {
  color: #c39d6d;
}

.pricing-currency {
  bottom: -32px;
  font-size: 18px;
  font-weight: 700;
  position: relative;
  vertical-align: 30px;
}

.pricing-price {
  font-size: 55px;
  font-weight: 700;
}

.pricing-period {
  font-weight: 700;
}

.pricing-table-content li {
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.pricing-table-content li:last-child {
  border-bottom: 0;
  padding-bottom: 0px;
}

.pricing-table-button {
  padding: 15px 0 35px;
}

.stacked .pricing-table {
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
}

.stacked .pricing-table {
  position: relative;
  z-index: 1;
}

.stacked .featured.pricing-table {
  position: relative;
  z-index: 2;
  margin-top: -32px;
}

.stacked .featured .pricing-table-price {
  padding: 45px 20px;
}

.stacked .featured .pricing-table-button {
  padding: 35px 0 55px;
}

.stacked .col-md-3 .featured.pricing-table {
  margin-top: -22px;
}

.stacked .col-md-3 .featured .pricing-table-price {
  padding: 40px 20px;
}

.stacked .col-md-3 .featured .pricing-table-button {
  padding: 25px 0 45px;
}

/* End Pricing Tables */
/* End About Pages */
/* Blog Pages */
.blog.block img {
  margin: 0;
}

.blog .btn {
  margin: 0;
}

.blog .btn-social {
  margin-left: 0;
}

.post-info-box {
  border: 1px solid #ebebeb;
  padding: 25px;
}

.blog-post-footer {
  border-top: 1px solid #ebebeb;
  padding-top: 30px;
  margin-top: 30px;
  margin-bottom: 10px !important;
}

.blog-post-footer .post-icons {
  margin-top: 10px;
}

#blog .about-sidebar-widget img {
  margin-bottom: 25px;
}

#blog .tags-sidebar-widget .btn {
  margin: 5px 0 5px 5px;
}

#blog-masonry {
  padding-top: 20px;
  padding-bottom: 50px;
}

#blog .padd15 {
  padding: 0 15px;
}

#blog .post-content-area p {
  margin-bottom: 20px;
}

#blog .post-category {
  padding: 15px 0;
  border-bottom: 1px solid #ebebeb;
}

#blog .post-category span {
  color: #666666;
}

#blog .blog-post-small {
  padding-bottom: 20px;
}

#blog .blog-post-small.first-post {
  border-bottom: 1px solid #cccccc;
  margin-top: 0;
}

#blog .blog-post-small.mid-post {
  border-bottom: 1px solid #cccccc;
  margin-top: 25px;
}

#blog .blog-post-small.last-post {
  margin-top: 25px;
  padding-bottom: 0;
}

#blog .blog-posts-small img {
  max-width: 66px;
  margin: 0 10px 0 0;
  float: left;
}

#blog .blog-posts-small a {
  font-size: 14px;
}

#blog .carousel-control .lnr-chevron-left {
  position: absolute;
  color: white;
  top: 44%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
  text-align: start;
}

#blog .carousel-control .lnr-chevron-right {
  position: absolute;
  color: white;
  top: 44%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
}

.media-object {
  max-width: 175px;
  max-height: 175px;
}

.media-left {
  float: left !important;
  margin-right: 15px;
}

.media-right {
  float: right !important;
}

.post-footer {
  padding: 10px 0;
  min-height: 120px;
}

.post-author-block {
  background: #ebebeb;
  padding: 60px 30px;
  text-align: left;
}

.post-author-block img {
  max-width: 100px;
  float: left;
  margin-right: 30px;
  border: 3px solid white;
  border-radius: 100%;
}

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
	line-height: 0;
}

.post-author-block p {
  margin-bottom: 0;
}
.blog-wrapper{
  position: relative;
}
.blog-wrapper .slick-prev {
	position: absolute;
	z-index: 99;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}
.blog-wrapper button {
  height: 60px;
  width: 60px;
  background-color: #222;
  color: #fff;
  text-align: center;
  line-height: 60px;
  font-size: 25px;
  border: 0;
}
.blog-wrapper-2 button {
  height: 30px;
  width: 30px;
  background-color: #222;
  color: #fff;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  border: 0;
}
.blog-wrapper .slick-next {
	position: absolute;
	z-index: 99;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}
.comments {
  margin-top: 60px;
}

.comments img {
  max-width: 67px;
  border-radius: 50%;
  margin-bottom: 20px;
}
.comment {
	margin-bottom: 30px;
}

.comment-date {
  font-size: 12px;
  margin-bottom: 2px;
}

.comment-author {
  font-size: 14px;
  margin-bottom: 2px;
}

.comment-author a {
  font-weight: 400;
}

.comment p {
  font-size: 13px;
  margin-bottom: 2px;
}

.comment-log-in {
  font-size: 12px;
  font-weight: 400;
}

/* Comment Form */
#comment-form {
  margin: 50px 0;
}

#commentForm .form-control {
  color: #000;
  background: #f8f8f8;
  font-weight: 400;
  letter-spacing: 0.05em;
  border: 1px solid #ebebeb;
  border-radius: 0;
  margin-bottom: 30px;
  padding: 10px 20px;
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#commentForm textarea {
  max-width: 100%;
  min-height: 130px !important;
  max-height: 211px;
  margin-bottom: 30px;
}

#comment-form .form-group {
  margin-bottom: 0;
}

#comment-form #commentForm {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#comment-form .btn-form-submit {
  width: 100%;
  border-radius: 0;
  padding: 14px 24px;
  margin: 0;
}

/* End Comment Form */
#blog .embed-responsive {
  margin-bottom: 0;
}

.pagination {
  margin-top: 50px;
  padding: 25px 0;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  border-radius: 0;
  display: block;
}

#blog blockquote {
  font-size: 14px;
}

/* End Blog Pages */
/* Contact Pages */
.contact-info {
  padding: 30px;
}

.contact-icon {
  margin-bottom: 10px;
}

.contact-icon i {
  color: #444;
  font-size: 25px;
}

.contact-info p {
  font-weight: 700;
}

.social-contact a i {
  margin-left: 3px;
}

.contact-area .map {
  padding-left: 0;
}

.contact-area .map-full {
  padding: 0;
}

.contact-form-area {
  padding-right: 0;
}

#map {
  width: 100%;
  height: 382px;
}

.contact-text img {
  margin-bottom: 15px;
  padding-left: 0;
}

/* Contact Form */
.form-control {
  color: #666666;
  background: #f8f8f8;
  font-weight: 400;
  letter-spacing: 0.05em;
  border: 1px solid #ebebeb;
  border-radius: 0;
  margin-bottom: 30px;
  padding: 10px 20px;
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#contactForm .form-control {
  color: #666666;
  background: #f8f8f8;
  font-weight: 400;
  letter-spacing: 0.05em;
  border: 1px solid #ebebeb;
  border-radius: 0;
  margin-bottom: 30px;
  padding: 10px 20px;
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#contactForm textarea {
  max-width: 100%;
  min-height: 130px !important;
  max-height: 211px;
  margin-bottom: 30px;
  padding: 20px !important;
}

::-webkit-input-placeholder {
  color: #cccccc !important;
  font-size: 12px !important;
  font-weight: 700;
  letter-spacing: 0.1em;
}



*::-moz-placeholder {
  color: #444 !important;
  font-size: 12px !important;
  font-weight: 700;
  letter-spacing: 0.1em;
}

:-ms-input-placeholder {
  color: #cccccc !important;
  font-size: 12px !important;
  font-weight: 700;
  letter-spacing: 0.1em;
}

.has-error .form-control {
  color: #de3f3f !important;
  border: 1px solid #de3f3f !important;
}

.help-block.with-errors li {
  color: #de3f3f;
  font-size: 13px;
  margin-top: -30px;
  margin-bottom: 0;
}

#msgSubmit.h3 {
  font-size: 14px;
  margin-top: 5px;
}

#contact-form .form-group {
  margin-bottom: 0;
}

#contact-form #contactForm {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#contact-form .btn-form-submit {
  width: 100%;
  font-weight: 700;
  letter-spacing: 0.1em;
  border-radius: 0;
  padding: 14px 24px;
  margin: 0;
}

/* End Contact Form */
/* End Contact Pages */
/* 404 Page */
.pnf-content {
  padding: 80px 15px;
}

/* End 404 Page */
/* End Single Pages */
/* Content */
/* Footers */
footer {
  background: white;
  border-top: 1px solid #ebebeb;
  padding: 30px 0;
}

footer.dark {
  border-top: 1px solid black;
}

.footer-info {
  padding: 0 15px;
}

footer p {
  color: #111111;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
}

footer.dark p {
  color: white;
}

footer .social a {
  font-size: 14px;
}
.dark .social a {
  color: #fff;
}

/* End Footers */
/* Scroll to Top */
.scroll-to-top {
  background: #c39d6d;
  color: white;
  width: 30px;
  height: 30px;
  bottom: 20px;
  right: 20px;
  line-height: 25px;
  border: 2px solid #c39d6d;
  border-radius: 50%;
  position: fixed;
  text-align: center;
  font-size: 18px;
  z-index: 99;
}
.scroll-to-top.is-hidden {
  opacity: 0;
  -webkit-transform: translate(0, -5px);
  -webkit-transition: -webkit-transform 0.2s, background 0.2s, color 0.2s, opacity 0 0.2s;
}
.scroll-to-top.is-visible {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -webkit-transition: -webkit-transform .2s,background .2s,color .2s;
}

.scroll-to-top.style-2 {
  border-radius: 0;
}

.scroll-to-top:hover {
  background: #ad8b60;
  color: white;
  border: 2px solid #ad8b60;
}

.scroll-to-top:focus {
  background: #ad8b60;
  color: white;
  border: 2px solid #ad8b60;
}

/* End Scroll to Top */
/* Preloader */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f8f8f8;
  z-index: 999999;
}

.spinner {
  width: 50px;
  height: 50px;
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  top: 45%;
  left: 48%;
}

.spinner-round:before {
  border-radius: 50%;
  content: " ";
  width: 50px;
  height: 50px;
  display: inline-block;
  box-sizing: border-box;
  border-top: solid 4px #333;
  border-right: solid 4px #333;
  border-bottom: solid 4px #333;
  border-left: solid 4px #333;
  position: absolute;
  top: 0;
  left: 0;
}

.spinner-round:after {
  border-radius: 50%;
  content: " ";
  width: 50px;
  height: 50px;
  display: inline-block;
  box-sizing: border-box;
  border-top: solid 4px #fff;
  border-right: solid 4px transparent;
  border-bottom: solid 4px transparent;
  border-left: solid 4px transparent;
  position: absolute;
  top: 0;
  left: 0;
  animation: spinner-round-animate 1s ease-in-out infinite;
}

@keyframes spinner-round-animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* End Preloader */
/* Demo */
body.demo {
  overflow: auto;
  background: #f8f8f8;
}

#pages .shuffle-item {
  height: auto;
}

#demo {
  background-color: #cccccc;
}

.demo .dark a:hover {
  opacity: 1 !important;
}

#demo .header-content {
  padding: 50px 0;
}

#demo .header-content-details {
  padding: 20px 0;
}

#demo .header-details-text {
  padding: 15px 250px;
}

#demo p {
  font-size: 14px;
}

#demo h3 {
  font-weight: 800;
  letter-spacing: 0.1em;
}

#demo .header-brand {
  margin-bottom: 20px;
}

#demo .header-brand img {
  width: auto;
  max-height: 40px;
}

#demo img {
  max-width: 160px;
}

#demos {
  padding-top: 50px;
}

#pages {
  padding-top: 50px;
}

.demo-item {
  margin-bottom: 30px;
  -webkit-box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
}

.demo-item a img {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.demo-item a img:hover {
  opacity: 0.7;
}

.demo-info {
  background: white;
  border-top: 1px solid #ebebeb;
  padding: 20px;
}

.demo-title {
  color: #333333;
  font-size: 18px;
  font-family: "Raleway", sans-serif;
  margin-bottom: 0;
}

.demo-description {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0;
}

.cta-line {
  background: #c39d6d;
  padding: 30px 0;
}

.cta-content-info h4 {
  margin-top: 16px;
}

.cta-line h4 {
  color: white;
  margin-bottom: 0;
}


/* Responsive */
@media only screen and (max-width: 991px) {
  #demo .header-details-text {
    padding: 15px 150px;
  }
}
@media only screen and (max-width: 600px) {
  #demo .header-details-text {
    padding: 15px;
  }

  .cta-line {
    text-align: center;
  }

  .cta-line .btn {
    margin: 30px auto 0 auto;
  }

  .cta-line .pull-left, .cta-line .pull-right {
    float: none !important;
  }
}
/* End Demo */
/* Media Screens */
@media only screen and (max-width: 1200px) {
  .project-hover-tools {
    bottom: 45%;
    right: 34%;
  }

  .project:hover .project-hover-tools {
    bottom: 42%;
    right: 34%;
  }

  .m-project .project-hover-tools {
    bottom: 47%;
  }

  .project.m-project:hover .project-hover-tools {
    bottom: 44%;
  }

  #portfolio.info-box .project-hover-tools {
    right: 34%;
  }

  #portfolio.info-box .project:hover .project-hover-tools {
    right: 34%;
  }

  #portfolio .container-fluid .m-project .project-hover-tools {
    bottom: 49%;
  }

  #portfolio .container-fluid .project.m-project:hover .project-hover-tools {
    bottom: 46%;
  }

  .pricing-currency {
    font-size: 14px;
    vertical-align: 20px;
  }

  .pricing-price {
    font-size: 40px;
  }

  .pricing-table .pricing-table-content ul li {
    font-size: 12px;
  }
}
@media only screen and (max-width: 991px) {
  .split-menu .pull-left {
    float: none !important;
  }

  .split-menu .navbar-brand {
    display: block;
  }

  .split-menu .pull-right {
    float: none !important;
  }

  .split-menu .navbar-nav {
    float: none;
  }

  .split-menu .navbar-nav > li {
    float: none;
    display: inline-block;
  }

  .nav > li > a {
    padding: 12.5px 10px;
  }

  .open .dropdown-menu > li > a {
    font-size: 13px;
  }

  .project-hover-tools {
    bottom: 45%;
    right: 36%;
  }

  .project:hover .project-hover-tools {
    bottom: 42%;
    right: 36%;
  }

  .m-project .project-hover-tools {
    bottom: 48%;
  }

  .project.m-project:hover .project-hover-tools {
    bottom: 45%;
  }

  #portfolio.info-box .project-hover-tools {
    right: 36%;
  }

  #portfolio.info-box .project:hover .project-hover-tools {
    right: 36%;
  }

  #portfolio .container-fluid .project-hover-tools {
    bottom: 47%;
    right: 39%;
  }

  #portfolio .container-fluid .project:hover .project-hover-tools {
    bottom: 44%;
    right: 39%;
  }

  #portfolio.info-box .container-fluid .project-hover-tools {
    bottom: 59%;
  }

  #portfolio.info-box .container-fluid .project:hover .project-hover-tools {
    bottom: 56%;
  }

  .log-line .checkbox label {
    padding-left: 8px;
  }

  .widget-area .col-md-4, .widget-area .col-md-3 {
    padding: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .navbar-nav {
    padding-left: 0;
  }

  .navbar-nav > li > a {
    line-height: 50px;
    padding: 0 !important;
  }

  .navbar-brand {
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    height: auto;
    padding: 20px 5px;
  }

.stacked-menu .navbar-brand {
  float: left;
  margin-top: -68px;
  padding-bottom: 20px;
}

  .open .dropdown-menu > li > a {
    padding: 5px 20px;
    text-align: center;
    max-width: 100%;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover {
    color: #c39d6d;
    background-color: transparent;
  }

  .dropdown-toggle span {
    margin-left: 15px;
  }

  .navbar-nav .open .dropdown-menu {
    max-width: 100%;
    margin: 0 auto;
  }

  .navbar-nav .open .dropdown-menu > li > a {
    padding: 10px 0;
  }

  .navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: transparent;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #111111;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #c39d6d;
    background-color: transparent;
  }

  .navbar .btn {
    margin: 7px 0 20px 0;
  }

  .banner-content {
    padding: 100px 0;
  }

  #portfolio .container-fluid .project-hover-tools {
    bottom: 45%;
    right: 35%;
  }

  #portfolio .container-fluid .project:hover .project-hover-tools {
    bottom: 42%;
    right: 35%;
  }

  #portfolio.info-box .container-fluid .project-hover-tools {
    bottom: 60%;
  }

  #portfolio.info-box .container-fluid .project:hover .project-hover-tools {
    bottom: 57%;
  }

  .project-sidebar.right {
    border-top: 2px solid #ebebeb;
    margin-top: 30px;
    padding: 20px 15px;
  }

  .section-w-image {
    padding: 0 15px;
  }

  .about-img {
    position: relative;
    height: 450px;
  }

  .fact-title {
    margin-bottom: 30px;
  }

  .pricing-table {
    max-width: 80%;
    margin: 30px auto;
  }

  .stacked > .col-md-4, .stacked > .col-md-3 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .stacked .col-md-3 .featured.pricing-table {
    margin-top: 0;
  }

  .stacked .featured.pricing-table {
    margin-top: 0;
  }

  #team .col-sm-4 {
    padding: 0;
  }

  .team {
    width: 80%;
    margin: 0 auto 30px auto;
  }

  .contact-info {
    padding: 5px 15px;
  }

  .contact-area .map {
    padding-right: 0;
    margin-bottom: 30px;
  }

  .contact-form-area {
    padding-right: 15px;
  }

  .contact-text {
    margin-bottom: 30px;
    padding-left: 15px;
  }

  #map {
    height: 300px;
  }
}
@media only screen and (max-width: 660px) {
  .project-hover-tools {
    bottom: 44%;
    right: 34%;
  }

  .project:hover .project-hover-tools {
    bottom: 41%;
    right: 34%;
  }

  #portfolio.info-box .project-hover-tools {
    right: 34%;
  }

  #portfolio.info-box .project:hover .project-hover-tools {
    right: 34%;
  }

  #portfolio.info-box .m-project .project-hover-tools {
    bottom: 58.5%;
  }

  #portfolio.info-box .project.m-project:hover .project-hover-tools {
    bottom: 55.5%;
  }

  #portfolio.info-box .container-fluid .project-hover-tools {
    bottom: 62%;
  }

  #portfolio.info-box .container-fluid .project:hover .project-hover-tools {
    bottom: 59%;
  }
}
@media only screen and (max-width: 600px) {
  .col-xs-6 {
    width: 100%;
  }

  .project-hover-tools {
    bottom: 48%;
    right: 41%;
  }

  .project:hover .project-hover-tools {
    bottom: 45%;
    right: 41%;
  }

  .m-project .project-hover-tools {
    bottom: 50%;
  }

  .project.m-project:hover .project-hover-tools {
    bottom: 47%;
  }

  #portfolio.info-box .project-hover-tools {
    bottom: 58%;
    right: 41%;
  }

  #portfolio.info-box .project:hover .project-hover-tools {
    bottom: 55%;
    right: 41%;
  }

  #portfolio.info-box .m-project .project-hover-tools {
    bottom: 56%;
  }

  #portfolio.info-box .project.m-project:hover .project-hover-tools {
    bottom: 53%;
  }

  #portfolio .container-fluid .project-hover-tools {
    bottom: 48%;
    right: 42%;
  }

  #portfolio .container-fluid .project:hover .project-hover-tools {
    bottom: 45%;
    right: 42%;
  }

  #portfolio .container-fluid .m-project .project-hover-tools {
    bottom: 50%;
    right: 41%;
  }

  #portfolio .container-fluid .project.m-project:hover .project-hover-tools {
    bottom: 47%;
    right: 41%;
  }

  #portfolio.info-box .container-fluid .project-hover-tools {
    bottom: 58%;
  }

  #portfolio.info-box .container-fluid .project:hover .project-hover-tools {
    bottom: 55%;
  }

  .about-img {
    height: 300px;
  }

  #contactForm .form-control {
    margin-bottom: 20px;
    padding: 10px;
    min-height: 40px;
  }

  #contactForm textarea {
    padding: 15px 10px !important;
  }

  #contact-form-1 .btn-form-submit {
    padding: 9px;
  }

  .help-block {
    margin-top: 20px;
  }

  .help-block.with-errors li {
    margin-top: -20px;
  }

  .footer-info {
    text-align: center;
    line-height: 1.8;
  }

  .footer-info .pull-right {
    float: none !important;
    display: block;
  }
}
@media only screen and (max-width: 550px) {
  .project-hover-tools {
    bottom: 48%;
    right: 40%;
  }

  .project:hover .project-hover-tools {
    bottom: 45%;
    right: 40%;
  }

  #portfolio.info-box .project-hover-tools {
    bottom: 59%;
    right: 40%;
  }

  #portfolio.info-box .project:hover .project-hover-tools {
    bottom: 56%;
    right: 40%;
  }

  #portfolio.info-box .m-project .project-hover-tools {
    bottom: 57%;
  }

  #portfolio.info-box .project.m-project:hover .project-hover-tools {
    bottom: 54%;
  }

  #portfolio .container-fluid .project-hover-tools {
    right: 40%;
  }

  #portfolio .container-fluid .project:hover .project-hover-tools {
    right: 40%;
  }

  #portfolio .container-fluid .m-project .project-hover-tools {
    right: 40%;
  }

  #portfolio .container-fluid .project.m-project:hover .project-hover-tools {
    right: 40%;
  }
}
@media only screen and (max-width: 480px) {
  .banner-content {
    padding: 80px 0;
  }

  .banner-border {
    width: 90%;
    padding: 10px;
  }

  .banner-border .banner-info {
    width: 100%;
    margin: 0 auto;
    padding: 50px;
  }

  .banner-info {
    width: 90%;
    padding: 50px;
  }

  .banner-info h1 {
    font-size: 20px;
  }

  .project-hover-tools {
    bottom: 47%;
    right: 38%;
  }

  .project:hover .project-hover-tools {
    bottom: 44%;
    right: 38%;
  }

  .m-project .project-hover-tools {
    bottom: 49%;
  }

  .project.m-project:hover .project-hover-tools {
    bottom: 46%;
  }

  #portfolio.info-box .project-hover-tools {
    bottom: 60%;
    right: 38%;
  }

  #portfolio.info-box .project:hover .project-hover-tools {
    bottom: 57%;
    right: 38%;
  }

  #portfolio .container-fluid .project-hover-tools {
    right: 39%;
  }

  #portfolio .container-fluid .project:hover .project-hover-tools {
    right: 39%;
  }

  #portfolio.info-box .container-fluid .project-hover-tools {
    bottom: 60%;
  }

  #portfolio.info-box .container-fluid .project:hover .project-hover-tools {
    bottom: 57%;
  }

  #map {
    height: 220px;
  }

  .spinner {
    left: 45%;
  }
}
@media only screen and (max-width: 400px) {
  .m-project .project-hover-tools {
    bottom: 48%;
  }

  .project.m-project:hover .project-hover-tools {
    bottom: 45%;
  }

  #portfolio.info-box .project-hover-tools {
    bottom: 60%;
    right: 36%;
  }

  #portfolio.info-box .project:hover .project-hover-tools {
    bottom: 57%;
    right: 36%;
  }

  #portfolio.info-box .m-project .project-hover-tools {
    bottom: 58%;
  }

  #portfolio.info-box .project.m-project:hover .project-hover-tools {
    bottom: 55%;
  }

  #portfolio .container-fluid .project-hover-tools {
    right: 38%;
  }

  #portfolio .container-fluid .project:hover .project-hover-tools {
    right: 38%;
  }

  .pricing-table {
    max-width: 90%;
  }

  .btn-direction.pull-right {
    float: none !important;
  }

  .team {
    width: 90%;
  }
}
@media only screen and (max-width: 360px) {
  .project-hover-tools {
    bottom: 44%;
    right: 32%;
  }

  .project:hover .project-hover-tools {
    bottom: 41%;
    right: 32%;
  }

  .m-project .project-hover-tools {
    bottom: 47%;
  }

  .project.m-project:hover .project-hover-tools {
    bottom: 44%;
  }

  #portfolio.info-box .project-hover-tools {
    bottom: 61%;
    right: 32%;
  }

  #portfolio.info-box .project:hover .project-hover-tools {
    bottom: 58%;
    right: 32%;
  }

  #portfolio.info-box .m-project .project-hover-tools {
    bottom: 59%;
  }

  #portfolio.info-box .project.m-project:hover .project-hover-tools {
    bottom: 56%;
  }

  #portfolio .container-fluid .project-hover-tools {
    bottom: 44%;
    right: 39%;
  }

  #portfolio .container-fluid .project:hover .project-hover-tools {
    bottom: 41%;
    right: 39%;
  }

  #portfolio .container-fluid .project-hover-tools {
    right: 36%;
  }

  #portfolio .container-fluid .project:hover .project-hover-tools {
    right: 36%;
  }

  #portfolio .container-fluid .m-project .project-hover-tools {
    bottom: 48%;
    right: 36%;
  }

  #portfolio .container-fluid .project.m-project:hover .project-hover-tools {
    bottom: 45%;
    right: 36%;
  }

  #portfolio.info-box .container-fluid .project-hover-tools {
    right: 32%;
  }

  #portfolio.info-box .container-fluid .project:hover .project-hover-tools {
    right: 32%;
  }
}
/* Min Widths */
@media only screen and (min-width: 1200px) {
  #portfolio .container-fluid .project-hover-tools {
    bottom: 47%;
    right: 39%;
  }

  #portfolio .container-fluid .project:hover .project-hover-tools {
    bottom: 44%;
    right: 39%;
  }

  #portfolio .container-fluid .m-project .project-hover-tools {
    bottom: 50%;
    right: 40%;
  }

  #portfolio .container-fluid .project.m-project:hover .project-hover-tools {
    bottom: 47%;
    right: 40%;
  }

  #portfolio.info-box .container-fluid .project-hover-tools {
    bottom: 59%;
  }

  #portfolio.info-box .container-fluid .project:hover .project-hover-tools {
    bottom: 56%;
  }

  #portfolio.two-col .project .project-hover-tools {
    right: 41% !important;
    bottom: 48%;
  }

  #portfolio.two-col .project:hover .project-hover-tools {
    right: 41% !important;
    bottom: 45%;
  }

  #portfolio.info-box.two-col .project .project-hover-tools {
    bottom: 58%;
  }

  #portfolio.info-box.two-col .project:hover .project-hover-tools {
    bottom: 55%;
  }

  #portfolio.two-col .project.m-project .project-hover-tools {
    bottom: 50%;
  }

  #portfolio.two-col .project.m-project:hover .project-hover-tools {
    bottom: 47%;
  }

  #portfolio.info-box.two-col .project.m-project .project-hover-tools {
    bottom: 56%;
  }

  #portfolio.info-box.two-col .project.m-project:hover .project-hover-tools {
    bottom: 53%;
  }

  #portfolio.four-col .project .project-hover-tools {
    right: 31% !important;
    bottom: 43%;
  }

  #portfolio.four-col .project:hover .project-hover-tools {
    right: 31% !important;
    bottom: 40%;
  }

  #portfolio.four-col .project.m-project .project-hover-tools {
    bottom: 47% !important;
  }

  #portfolio.four-col .project.m-project:hover .project-hover-tools {
    bottom: 44% !important;
  }
}
@media only screen and (min-width: 992px) {
  #portfolio.two-col .project .project-hover-tools {
    right: 39%;
  }

  #portfolio.two-col .project:hover .project-hover-tools {
    right: 39%;
  }

  #portfolio.four-col .project .project-hover-tools {
    right: 28%;
    bottom: 43%;
  }

  #portfolio.four-col .project:hover .project-hover-tools {
    right: 28%;
    bottom: 40%;
  }

  #portfolio.info-box.four-col .project .project-hover-tools {
    bottom: 61%;
  }

  #portfolio.info-box.four-col .project:hover .project-hover-tools {
    bottom: 58%;
  }

  #portfolio.four-col .project.m-project .project-hover-tools {
    bottom: 46%;
  }

  #portfolio.four-col .project.m-project:hover .project-hover-tools {
    bottom: 43%;
  }

  #portfolio.info-box.four-col .project.m-project .project-hover-tools {
    bottom: 59%;
  }

  #portfolio.info-box.four-col .project.m-project:hover .project-hover-tools {
    bottom: 56%;
  }
}
@media only screen and (min-width: 768px) {
  .navbar-nav {
    float: right;
  }
  .post-block h5 a {
    font-size: 14px;
  }
  .navbar-nav > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: 0;
  }

  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }
}
@media only screen and (min-width: 576px) {
  .tp-logo{
    padding: 20px 0;
  }
}
@media only screen and (min-width: 330px) {
  .tp-logo{
    padding: 20px 0;
  }
}
/* End Min Widths */
/* / End Media Screens */

.form-messege {
  margin-top: 20px;
}



/* offcanvas-start */

.body-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(24, 24, 24, 0.6);
  visibility: hidden;
  opacity: 0;
  transition: 0.45s ease-in-out;
}
.body-overlay.apply {
  opacity: 1;
  visibility: visible;
}


.tp-instagram img {
  width: 100%;
}

.tpoffcanvas {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  right: -100%;
  width: 480px;
  bottom: 0;
  box-shadow: 0 16px -32px 0 rgba(0, 0, 0, 0.8);
  background-color: #222;
  z-index: 9999;
  padding: 50px;
  scrollbar-width: none;
  opacity: 0;
  visibility: hidden;
  transition: 0.45s ease-in-out;
  overflow-y: scroll;
}
.tpoffcanvas.opened {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767px) {
  .tpoffcanvas {
    width: 300px;
    padding: 40px 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tpoffcanvas {
    width: 400px;
    padding: 40px;
  }
}
.tpoffcanvas.opened {
  right: 0;
  -webkit-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-duration: 0.6s;
}
.tpoffcanvas__logo {
  margin-bottom: 40px;
}
@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tpoffcanvas__logo {
    padding-top: 0;
  }
}
.tpoffcanvas__logo img {
  width: 140px;
  height: 100%;
}
.tpoffcanvas__close-btn button {
  font-size: 35px;
  color: white;
  position: absolute;
  right: 50px;
  top: 52px;
  transition: 1s;
  opacity: 0.2;
  -webkit-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-duration: 0.6s;
  background-color: transparent;
  border: 0;
}
.tp-info-wrapper{
  margin-bottom: 20px;
}
.tpoffcanvas__close-btn button i {
  font-weight: 300;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tpoffcanvas__close-btn button {
    right: 48px;
    top: 52px;
  }
}
@media (max-width: 767px) {
  .tpoffcanvas__close-btn button {
    right: 20px;
    top: 20px;
  }
}
.tpoffcanvas__close-btn button:hover {
  color: #fff;
  opacity: 1;
}
.tpoffcanvas__content {
  margin-bottom: 30px;
}
.tpoffcanvas__content p {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 25px;
}
.tpoffcanvas__content span {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  display: inline-block;
}
.tpoffcanvas__content a {
  font-size: 30px;
  line-height: 51px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
}
@media (max-width: 767px) {
  .tpoffcanvas__content a {
    font-size: 27px;
  }
}
.tpoffcanvas__social {
  margin-top: 50px;
}
.tpoffcanvas__social .social-icon a {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  background-color: #fff;
  color: #c00;
  margin-right: 15px;
  transition: 0.3s;
  display: inline-block;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .tpoffcanvas__social .social-icon a {
    margin-right: 3px;
  }
}
.tpoffcanvas__social .social-icon a:hover {
  background-color: #c00;
  color: #fff;
}
.tpoffcanvas__text {
  border-bottom: 1px solid #3b3838;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tpoffcanvas__text {
    display: none;
  }
}
.tpoffcanvas__text p {
  color: #fff;
  font-size: 18px;
}
.tpoffcanvas__info {
  border-bottom: 1px solid #3b3838;
  padding-bottom: 30px;
  margin-bottom: 20px;
}
.tpoffcanvas__info .offcanva-title {
  color: #fff;
  margin: 30px 0 40px 0;
  font-size: 20px;
  font-weight: 600;
}
.tpoffcanvas__info-icon a {
  height: 50px;
  width: 50px;
  background-color: #fff;
  color: #222;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 18px;
  margin-right: 20px;
}
.tpoffcanvas__info-icon a:hover {
  background-color: #c00;
  color: #fff;
}
.tpoffcanvas__info-address span {
  display: block;
  color:#fff;
  font-size: 16px;
  font-weight: 400;
}
.tpoffcanvas__info-address a {
  display: block;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  transition: 0.3s;
}
@media (max-width: 767px) {
  .tpoffcanvas__info-address a {
    font-size: 13px;
  }
}
.tpoffcanvas__info-address a:hover {
  color:#c00;
}

.mobile-menu.mean-container {
  margin-bottom: 50px;
}

.offcan-social-title {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  display: inline-block;
}

.tpoffcanvas-social {
  height: 100%;
  display: flex;
  flex-direction: column;
}
/* offcanvas-end */



/*----------------------------------------*/
/*  03. MEANMENU CSS START
/*----------------------------------------*/
.mobile-menu.mean-container {
  overflow: hidden;
}
@media (max-width: 767px) {
  .mobile-menu.mean-container {
    margin-bottom: 30px;
  }
}

.mobile-menu .sidebar-list {
  clear: both;
}
.mobile-menu .sidebar-list li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 5px;
  color: #fff;
}
.mobile-menu .sidebar-list li::after {
  top: 10px;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1px solid #c00;
  background-color: transparent;
  content: "";
  display: inline-block;
  position: absolute;
}
.mobile-menu .tp-sidebar-social {
  margin-top: 20px;
}
.mobile-menu .tp-sidebar-social a {
  margin-right: 5px;
  background-color: #c00;
  color: #fff;
  display: inline-block;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 14px;
  line-height: 34px;
}

.sidebar-title h3 {
  color:#fff;
}

/* mean menu customize */
.mean-container a.meanmenu-reveal {
  display: none;
}

.mean-container .mean-nav {
  background: none;
  margin-top: 0;
}

.mean-container .mean-bar {
  padding: 0;
  min-height: auto;
  background: none;
}

.mean-container .mean-nav > ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}

.mean-container a.meanmenu-reveal {
  display: none !important;
}

.mean-container .mean-nav ul li a {
  width: 100%;
  padding: 15px 0;
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 13px;
  line-height: 1.5;
  font-weight: 700;
}
.mean-container .mean-nav ul li a:hover {
  color: #c00;
}

.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 10px;
  padding: 0 !important;
  line-height: 14px;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  height: 30px;
  width: 30px;
  line-height: 30px;
  color: #fff;
  line-height: 30px;
  top: 0;
  font-weight: 400;
}
.mean-container .mean-nav ul li a.mean-expand:hover {
  background: #fff;
  color: #c00;
  border-color: #888888;
}

.mean-container .mean-nav ul li > a > i {
  display: none;
}

.mean-container .mean-nav ul li > a.mean-expand i {
  display: inline-block;
}

.mean-container .mean-nav > ul > li:first-child > a {
  border-top: 0;
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked {
  color: #222;
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked i {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  color: #c00;
}
/*----------------------------------------*/
/*  03. MEANMENU CSS end
/*----------------------------------------*/